<template>
  <header class="navbar pcoded-header noprint navbar-expand-lg navbar-light">
    <div class="m-header">
        <span class="b-bg ml-4">Dashboard<br><span style="font-size:10px;">iMessage API</span></span>
      <a
        :class="['mobile-menu', { 'on': isOpen }]"
        id="mobile-collapse1"
        href="#"
        @click.prevent="$emit('toggle')"
      ><span></span></a>
    </div>
    <div class="collapse navbar-collapse limitWidth">
      <ul class="navbar-nav ml-auto">
        <li>
          <language-element class="mr-md-4"
          ></language-element>
        </li>
        <li><p class="email-header">{{ license ? license.email : '' }}</p></li>
        <li><button class="displayChatbox logout" @click="logout"><i class="feather icon-log-out" ></i></button></li>
      </ul>
    </div>
  </header>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { useLogout } from '@/application/composables/logout.js'
import { useStore } from 'vuex'

export default {
  name: 'DashboardHeader',
  components: {
    'language-element': defineAsyncComponent(() => import('@/application/components/elements/LanguageElement.vue')),
  },
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  setup() {
    const { logout } = useLogout()
    const store = useStore()
    const license = computed(() => store.getters.getLicense)
    return {
      logout,
      license,
    }
  },
}
</script>
